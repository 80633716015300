import { useIntl } from 'react-intl';
import { Descriptions, Modal, Typography } from 'antd';

import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as InfoIcon } from 'assets/bosta-icons/info.svg';

import './ConfirmationModal.less';

const ConfirmationModal = ({
  packageCount,
  selectedContactPerson,
  handleSubmit,
  close,
  ...props
}) => {
  const intl = useIntl();
  const {
    business: { name: businessName },
    contactPerson: { name: personName, phone: personPhone }
  } = selectedContactPerson;
  const handleConfirm = () => {
    handleSubmit();
    close();
  };

  return (
    <Modal
      className="br-hubs-receive-from-business__confirmation-modal"
      footer={
        <>
          <BRButton label="Back" onClick={close} />
          <BRButton type="primary" onClick={handleConfirm} label="Confirm" />
        </>
      }
      width={360}
      {...props}
    >
      <div className="confirmation-modal__header">
        <div className="confirmation-modal__header__icon">
          <InfoIcon />
        </div>

        <Typography.Title level={4}>
          {intl.formatMessage({
            id: 'hubs.receive_from_business.confirmation_modal.title'
          })}
        </Typography.Title>

        <Typography.Title level={5}>
          {intl.formatMessage(
            {
              id: 'hubs.receive_from_business.confirmation_modal.subtitle'
            },
            { scannedPackagesCount: packageCount }
          )}
        </Typography.Title>
      </div>

      <Descriptions bordered column={1}>
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'hubs.receive_from_business.confirmation_modal.business'
          })}
        >
          {businessName}
        </Descriptions.Item>

        <Descriptions.Item
          label={intl.formatMessage({
            id: 'hubs.receive_from_business.confirmation_modal.contact_person'
          })}
        >
          {personName}
        </Descriptions.Item>

        <Descriptions.Item
          label={intl.formatMessage({
            id: 'hubs.receive_from_business.confirmation_modal.whatsapp'
          })}
        >
          {personPhone}
        </Descriptions.Item>
      </Descriptions>

      <Typography.Paragraph>
        {intl.formatMessage({
          id: 'hubs.receive_from_business.confirmation_modal.verification_note'
        })}
      </Typography.Paragraph>
    </Modal>
  );
};

export default ConfirmationModal;
