import { useState } from 'react';

import {formatPackages, formatReceivedOrders} from "utils/hubs";

import { notify } from 'components/Notify/Notify';

const useHubPackages = () => {
  const [packages, setPackages] = useState([]);

  const addPackage = (newPackage) => {
    setPackages((prev) => [...prev, newPackage]);
  };

  const removePackage = (id) => {
    setPackages((prev) => prev.filter((el) => el._id !== id));
  };

  const updatePackageWeight = ({ value, delivery }) => {
    if (!value) {
      return;
    }

    const weightValue = value.split('KG')[0];

    if (isNaN(weightValue)) {
      notify('Please enter number not text');
      return;
    }

    setPackages((prev) => {
      return [
        ...prev.map((d) => {
          if (d.trackingNumber === delivery?.trackingNumber) {
            d.packageWeight = parseInt(weightValue);
          }
          return d;
        })
      ];
    });
  };

  const clearPackages = () => setPackages([]);

  const showFailedPackages = (failedDeliveries) => {
    if (!failedDeliveries || !failedDeliveries.length) {
      clearPackages();
      return;
    }

    setPackages((prev) =>
      prev.filter((el) =>
        failedDeliveries.some(
          (trackingNumber) => el.trackingNumber === trackingNumber
        )
      )
    );
  };

  return {
    packages,
    formatPackages,
    formatReceivedOrders,
    addPackage,
    removePackage,
    updatePackageWeight,
    clearPackages,
    showFailedPackages
  };
}

export default useHubPackages;