import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import classnames from 'classnames';

import { mapCancelReason } from 'utils/pickups';
import { PICKUP_REQUESTS_CANCEL_REASON, PICKUP_TYPES } from 'constants/pickups';
import { pickupStates } from 'constants/pickups';

import BRTag from 'components/BRTag/BRTag';

import { ReactComponent as AlertCircle } from 'assets/bosta-icons/alert-circle.svg';

import './PickupState.less';

export default function PickupState({
  item: { state, cancelReason, exceptionCode, type, isRescheduled },
  className,
  ...props
}) {
  const intl = useIntl();

  const initialStates = [pickupStates.Requested, pickupStates.Rescheduled];
  const intermediateStates = [pickupStates.Picking_up, pickupStates.Route];
  const exceptionStates = [pickupStates.Exception];
  const finalStates = [pickupStates.Picked];

  const cancellationMessage = exceptionCode
    ? PICKUP_REQUESTS_CANCEL_REASON[
        type === PICKUP_TYPES.FIRST_MILE_PICKUP
          ? PICKUP_TYPES.FIRST_MILE_PICKUP
          : PICKUP_TYPES.CRP_PICKUP
      ][exceptionCode]
    : cancelReason &&
      intl.formatMessage({
        id: `pickups.cancel_reasons.${
          cancelReason.includes('_')
            ? cancelReason
            : mapCancelReason[cancelReason]
        }`
      });

  const showRescheduledLabel = isRescheduled && state === pickupStates.Requested;
  const stateLabel = showRescheduledLabel ? pickupStates.Rescheduled : state;

  return (
    <div
      className={classnames('br-pickups__state', { [className]: !!className })}
      {...props}
    >
      <BRTag
        color={
          initialStates.includes(stateLabel)
            ? 'blue'
            : intermediateStates.includes(stateLabel)
            ? 'yellow'
            : finalStates.includes(stateLabel)
            ? 'green'
            : 'red'
        }
      >
        {stateLabel}
      </BRTag>
      {cancellationMessage ? (
        <Tooltip placement="bottom" title={cancellationMessage}>
          <AlertCircle />
        </Tooltip>
      ) : (
        exceptionStates.includes(stateLabel) && (
          <Tooltip
            placement="bottom"
            title={intl.formatMessage({
              id: 'pickups.pickup_request.exception_validation_tooltip'
            })}
          >
            <AlertCircle />
          </Tooltip>
        )
      )}
    </div>
  );
}
