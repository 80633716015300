import { useEffect, useState } from 'react';

import { getPickupStars } from 'services/hubs';

import { notify } from 'components/Notify/Notify';

const useHubStars = () => {
  const [loading, setLoading] = useState(false);
  const [stars, setStars] = useState([]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { message } = await getPickupStars();
        message &&
          setStars(message.filter((el) => el.emails?.[0]?.verified) || []);
      } catch (error) {
        notify(error.message);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return {
    stars,
    loadingStars: loading
  };
}

export default useHubStars;