import { useIntl } from 'react-intl';
import { Form, Select } from 'antd';

import { useHubsContext } from 'containers/Hub/ReceiveFromBusiness/ReceiveFromBusiness';

import { ReactComponent as ProfileIcon } from 'assets/bosta-icons/Profile.svg';

import './ContactPersonSelect.less';

const ContactPersonSelect = () => {
  const intl = useIntl();
  const { businessContactPersons, loading } = useHubsContext();
  const firstContactPerson = businessContactPersons[0]?.contactPerson;

  const contactPersonListDisabled =
    loading || businessContactPersons.length < 2;

  return (
    <div className="pickup-scanning__business-info__contact-person">
      <label htmlFor="contactPerson">
        {intl.formatMessage({
          id: 'hubs.receive_from_business.pickup_scanning.business_info.contact_person'
        })}
        <br />
        <span>
          {intl.formatMessage({
            id: 'hubs.receive_from_business.pickup_scanning.business_info.contact_person_note'
          })}
        </span>
      </label>
      {firstContactPerson && (
        <Form.Item
          name="contactPerson"
          rules={[
            { required: true, message: 'Please select a contact person' }
          ]}
          initialValue={firstContactPerson._id}
        >
          <Select
            dropdownMatchSelectWidth
            disabled={
              contactPersonListDisabled || businessContactPersons.length === 1
            }
            suffixIcon={<ProfileIcon />}
            getPopupContainer={(trigger) => trigger.parentNode}
            value={firstContactPerson._id}
          >
            {businessContactPersons.map(
              ({ contactPerson: { _id, name, phone } }) => (
                <Select.Option key={_id} value={_id}>
                  {name}
                  <span className="business-info__contact-person__phone-number">
                    {phone}
                  </span>
                </Select.Option>
              )
            )}
          </Select>
        </Form.Item>
      )}
      {!firstContactPerson && <Select disabled></Select>}
    </div>
  );
};

export default ContactPersonSelect;
