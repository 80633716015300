import http from 'utils/http';
import { downloadHeader } from 'utils/download';

export const getHubs = (payload) => {
  return http.get('/hubs', payload);
};

export const createHub = (payload) => {
  return http.post(`/hubs`, payload);
};

export const editHub = (hubId, payload) => {
  return http.put(`hubs/${hubId}`, payload);
};

export const getHubStars = (hubId) => {
  return http.get(`/hubs/${hubId}/stars`);
};

export const deliveriesDebrief = (starId, params) => {
  return http.post(`/admin/deliveries/${starId}/debrief-temp`, { params });
};

export const endDeliveryDebrief = (payload) => {
  return http.put(`/routes/end-debrief-temp`, payload);
};

export const createTicket = (payload) => {
  return http.post(`/tickets`, payload);
};

export const receivePackagesAtHub = (payload) => {
  return http.put(`/admin/deliveries/updateMany`, payload);
};

export const checkOrderExistance = (payload) => {
  return http.get(`/admin/deliveries`, payload);
};

export const addDeliveryToPickup = ({ pickupId, payload }) => {
  return http.put(`/pickups/${pickupId}/add-deliveries`, payload);
};

export const confirmReceivingDeliveries = (payload) => {
  return http.put('/admin/deliveries/confirmDeliveries', payload);
};

export const collectStarMoney = (payload) => {
  return http.post('/cash/collect-star-money', payload);
};

export const getHub = (hubId) => {
  return http.get(`/hubs/${hubId}`);
};

export const getNextWorkingDay = () => {
  return http.get('sla/nextWorkingDays?days=1');
};

export const linkDelivery = (deliveryId, payload) => {
  return http.put(`/admin/deliveries/${deliveryId}/link-pickup`, payload);
};

export const getRTODeliveries = (hubId, payload) => {
  return http.get(`/admin/deliveries/${hubId}/dispatch/RTO`, payload);
};

export const scanDeliveries = (payload) => {
  return http.get(`/admin/deliveries/tailored/scan-deliveries`, payload);
};

export const getHubShelvesCode = (hubId) => {
  return http.get(`/hubs/${hubId}/generateShelfCode`);
};

export const getHubShelves = (hubId) => {
  return http.get(`/hubs/${hubId}/listHubShelves`);
};

export const getRoutes = (payload) => {
  return http.get('/routes', payload);
};

export const assignToRoute = (routeId, payload) => {
  return http.put(`/routes/${routeId}/assign-rto-group`, payload);
};

export const getSortingFacility = (selectSortingTypeOnly = false) => {
  return http.get(
    `hubs/sortingHub${selectSortingTypeOnly ? '?type=SORTING_HUB' : ''}`
  );
};

export const fetchHubDispatching = (payload) => {
  return http.post('/admin/deliveries/hub-dispatching', payload);
};

export const fetchHubDispatchingOverview = () => {
  return http.get('/admin/deliveries/hub-dispatching/overview');
};

export const hubDispatchingExport = (payload) => {
  return http.post('/admin/deliveries/hub-dispatching/export', payload);
};

export const fetchHubTransferringOverview = () => {
  return http.get('/admin/deliveries/hub-transferring/overview');
};

export const hubTransferringExport = (payload) => {
  return http.post('/admin/deliveries/hub-transferring/export', payload);
};

export const fetchHubTransferring = (payload) => {
  return http.post('/admin/deliveries/hub-transferring', payload);
};

export const getSeal = ({ sealNumber, scanType, payload }) => {
  return http.get(`/seals/${sealNumber}/scans/${scanType}`, payload);
};

export const createSeal = (payload) => {
  return http.post('/seals', payload);
};

export const updateSeal = (payload) => {
  return http.put('/seals', payload);
};

export const searchSeal = (payload) => {
  return http.get('/seals/search', payload);
};

export const deleteTrackingNumberFromSeal = (sealNumber, trackingNumber) => {
  return http.delete(`/seals/${sealNumber}?trackingNumber=${trackingNumber}`);
};

export const receiveSealsTNs = (sealNumber, payload) => {
  return http.put(`/seals/${sealNumber}`, payload);
};

export const createHubTransfer = (payload) => {
  return http.post('/warehouseTransfer', payload);
};

export const scanShelves = ({ hubId, payload }) => {
  return http.get(`/hubs/${hubId}/scanShelves`, payload);
};

export const createMissedPickupsTicket = (payload) => {
  return http.post('/pickups/createPickupTicket', payload);
};

export const setMissedPickupsDeliveries = (payload) => {
  return http.post('/pickups/setMissedOrders', payload);
};

export const setConfirmedPickupsDeliveries = (payload) => {
  return http.post('/pickups/setDoneOrders', payload);
};

export const getPickupStars = () => {
  return http.get('/hubs/pickup-stars');
};

export const getHubNotifications = ({ hubId, payload }) => {
  return http.get(`/notifications/hubs/${hubId}`, payload);
};

export const fetchNotDebriefedCashMoney = (starId, payload) => {
  return http.get(`/cash/cashMoney-not-debriefed-for-star/${starId}`, payload);
};

export const changeCashMoneyType = (cashId, payload) => {
  return http.put(`/cash/cashMoney-change-type/${cashId}`, payload);
};

export const generateBarcodes = (payload) => {
  return http.post('/barcode/generate', payload);
};

export const OFDDeliveriesSearch = (payload) => {
  return http.get('/deliveries/attempts/search', payload);
};

export const ofdDeliveriesExport = (payload) => {
  return http.get('/deliveries/attempts/export', payload);
};

export const bulkAddCashDebriefRecords = (payload) => {
  return http.post('/cash/bulk-collect-stars-money', payload);
};

export const searchReceivingManifests = (payload) => {
  return http.get('/receivingManifest', payload);
};

export const generateBulkySeals = (payload) => {
  return http.post('/seals/bulkySeals', payload);
};

export const receiveBulkySeals = (payload) => {
  return http.put('/seals/bulkyTransfer', payload);
};

export const deleteBulkySeal = (sealNumber) => {
  return http.delete(`/seals/deleteSeal/${sealNumber}`);
};

export const printBulkySeal = (payload) => {
  return http.get('/seals/printBulkySealsInBulk', payload);
};

export const searchScanLogs = (payload) => {
  return http.get('/deliveries/scansLogs/search', payload);
};

export const exportScanLogs = (payload) => {
  return http.get('/deliveries/scansLogs/export', payload, downloadHeader);
};

export const removeScannedDeliveries = (payload) => {
  return http.post('admin/deliveries/remove-scanned-deliveries', payload);
};
