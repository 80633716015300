import { useEffect, useState } from 'react';

import { formatPickupsData } from 'utils/hubs';
import { deliveriesDebrief } from 'services/hubs';

import { notify } from 'components/Notify/Notify';

const useDeliveriesDebrief = (selectedStar) => {
  const [loading, setLoading] = useState(false);
  const [pickups, setPickups] = useState({});
  const [firstMilePickupsCount, setFirstMilePickupCount] = useState(0);

  const removeDeliveryFromPickups = (item) => {
    const businessName = item.sender?.name;
    if (!pickups[businessName]) {
      return;
    }

    if (pickups[businessName].originalDeliveriesCount) {
      setPickups((currentPickups) => ({
        ...currentPickups,
        [businessName]: {
          ...currentPickups[businessName],
          deliveriesCount: currentPickups[businessName].deliveriesCount - 1
        }
      }));
    } else {
      setPickups((currentPickups) => ({
        ...currentPickups,
        [businessName]: {
          ...currentPickups[businessName],
          deliveries: currentPickups[businessName].deliveries.filter(
            (delivery) => delivery.trackingNumber !== item.trackingNumber
          )
        }
      }));
    }

    setFirstMilePickupCount((c) => c - 1);
  };

  const addDeliveryToPickups = (businessName, delivery) => {
    if (selectedStar && firstMilePickupsCount) {
      if (pickups[businessName].originalDeliveriesCount > 0) {
        setPickups({
          ...pickups,
          [businessName]: {
            ...pickups[businessName],
            deliveriesCount: pickups[businessName].deliveriesCount + 1
          }
        });
      } else {
        setPickups({
          ...pickups,
          [businessName]: {
            ...pickups[businessName],
            deliveries: [...pickups[businessName].deliveries, delivery]
          }
        });
      }

      setFirstMilePickupCount(firstMilePickupsCount + 1);
    }
  };

  const clearPickups = () => {
    setPickups({});
    setFirstMilePickupCount(0);
  };

  useEffect(() => {
    if (!selectedStar) {
      return;
    }

    (async () => {
      setLoading(true);
      try {
        const { pickups: response } = await deliveriesDebrief(selectedStar);
        const { formattedBusinessObject, packageCount } =
          formatPickupsData(response);
        setPickups(formattedBusinessObject);
        setFirstMilePickupCount(packageCount);
      } catch (error) {
        notify(error.message);
      }
      setLoading(false);
    })();
  }, [selectedStar]);

  return {
    removeDeliveryFromPickups,
    addDeliveryToPickups,
    clearPickups,
    pickups,
    firstMilePickupsCount,
    loadingDeliveriesDebrief: loading
  };
}

export default useDeliveriesDebrief;