import React, { useState } from 'react';
import { connect } from 'react-redux';
import xlsx from 'xlsx';
import { Button, FormGroup } from 'react-bootstrap';
import { Modal, Form, Select, Checkbox } from 'antd';
import dayjs from 'dayjs';
import { injectIntl } from 'react-intl';

import useSealNumberValidation from 'hooks/hubs/useSealNumberValidation';
import useTrackingNumberValidation from 'hooks/hubs/useTrackingNumberValidation';
import useHubPackages from 'hooks/hubs/useHubPackages';
import usePackageSizes from 'hooks/hubs/usePackageSizes';
import useHubStars from 'hooks/hubs/useHubStars';
import useDeliveriesDebrief from 'hooks/hubs/useDeliveriesDebrief';
import { IS_SAUDI } from 'constants/helper';
import { DELIVERY_UPDATE_TYPE } from 'constants/Deliveries';
import { NORMAL_PACKAGE_TYPE } from 'constants/pickups';
import { DELIVERIES_SCAN_TYPES } from 'constants/hubs';
import {
  setConfirmedPickupsDeliveries,
  removeScannedDeliveries
} from 'services/hubs';
import { beep, warning } from 'actions';
import { receivePackagesAtHub } from 'actions/DeliveriesActions';
import { setMissedPickupsDeliveries } from 'services/hubs';
import { openModal } from 'utils/modal';
import { validateScannedPackage } from 'utils/hubs';

import NewSwappingContainer from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/NewSwappingContainer/NewSwappingContainer';
import PickupsTabActions from 'components/Hub/PackagesDebrief/PackagesDebriefTabs/PickupsTabActions/PickupsTabActions';
import MissingMultiPackagesModal from 'components/MultiPackages/components/MissingMultiPackagesModal/MissingMultiPackagesModal';
import { notify } from 'components/Notify/Notify';

import styles from '../styles.module.scss';
import './ReceiveNewPickups.less';

const useWeights = IS_SAUDI;

/**
 * create xlsx file to
 * @param deliveries
 */
const createXLSX = (deliveries = []) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if (deliveries.length === 0) return;
  const mappedDeliveries = deliveries.map((el) => ({
    TN: el.trackingNumber,
    Business: el.sender.name,
    'Received by': `${userInfo?.profile?.firstName} ${
      userInfo?.profile?.lastName || ''
    }`,
    Mode: el.scanType,
    'Size Value': el?.pricingPackageSize || NORMAL_PACKAGE_TYPE,
    'Weight Value': el?.packageWeight
  }));

  const ws = xlsx.utils.json_to_sheet(mappedDeliveries);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'Deliveries');
  xlsx.writeFile(wb, `Deliveries-${dayjs(new Date()).tz().format('LL')}.xlsx`);
};

const addWrongScanLog = async (ids) => {
  const payload = {
    searchType: DELIVERIES_SCAN_TYPES.PICKUPS_RECEIVE_ORDER_SCAN,
    trackingNumbers: ids
  };
  try {
    await removeScannedDeliveries(payload);
  } catch (error) {
    notify(error.message);
  }
};

const ReceiveNewPickups = ({ playBeep, intl }) => {
  const [loadingPackagesSave, setLoadingPackagesSave] = useState(false);
  const [selectedStar, setSelectedStar] = useState(false);
  const [isMissedFirstMilePickups, setIsMissedFirstMilePickups] =
    useState(false);
  const [isGroupByBusiness, setIsGroupByBusiness] = useState(true);

  const [form] = Form.useForm();

  const { stars, loadingStars } = useHubStars();

  const { allPricingPackageSize, loadingPricingPackageSizes } =
    usePackageSizes();

  const {
    pickups,
    firstMilePickupsCount,
    removeDeliveryFromPickups,
    addDeliveryToPickups,
    clearPickups,
    loadingDeliveriesDebrief
  } = useDeliveriesDebrief(selectedStar);

  const {
    packages,
    formatPackages,
    formatReceivedOrders,
    addPackage,
    removePackage,
    updatePackageWeight,
    clearPackages,
    showFailedPackages
  } = useHubPackages();

  const { handleTrackingNumberChange, loadingTrackingNumberValidation } =
    useTrackingNumberValidation(
      packages,
      removeDeliveryFromPickups,
      (args) => {
        handleChangeOfPackages({ ...args, isScannedFromSeal: false });
      },
      [
        allPricingPackageSize,
        selectedStar,
        firstMilePickupsCount,
        pickups.length
      ]
    );

  const { handleSealNumberChange, loadingSealNumberValidation } =
    useSealNumberValidation(
      packages,
      (args) => {
        handleChangeOfPackages({
          ...args,
          isScannedFromSeal: true
        });
      },
      [allPricingPackageSize]
    );

  const loading = [
    loadingPackagesSave,
    loadingStars,
    loadingDeliveriesDebrief,
    loadingPricingPackageSizes,
    loadingTrackingNumberValidation,
    loadingSealNumberValidation
  ].some((el) => el);

  const handleChangeOfPackages = (args) => {
    const validatedPackage = validateScannedPackage({
      intl,
      packages,
      playBeep,
      ...args
    });
    if (!validatedPackage) {
      return;
    }

    addPackage(validatedPackage);
  };

  const handleSaveCurrentPackages = async () => {
    setLoadingPackagesSave(true);

    const res = await receivePackagesAtHub({
      updateType: DELIVERY_UPDATE_TYPE.RECEIVE_MANY_NEW_PICKUPS,
      deliveries: packages.map(
        ({ _id, packageSize, pricingPackageSize, scanType, packageWeight }) => {
          return {
            _id,
            packageSize,
            pricingPackageSize,
            scanType,
            packageWeight
          };
        }
      )
    });
    if (res) {
      if (res.failedDeliveries?.length > 0) {
        notify(
          `Although the success of receiving the packages there are some package failed to be received on the system, please review the following packages status: ${res.failedDeliveries.join(
            ', '
          )} `,
          'error'
        );
      } else {
        notify('Deliveries received successfully!', 'success');
      }
      if (firstMilePickupsCount > 0) {
        setIsMissedFirstMilePickups(true);
        Modal.confirm({
          content: (
            <div>
              Some pickups are missing, are you sure you want to confirm
              receiving without scanning them?
            </div>
          ),
          okButtonProps: { className: 'primaryButton' },
          cancelButtonProps: { className: 'secondaryButton' },
          onOk: async () => {
            handleSendingMissedPickups();
          }
        });
      }
      const filteredPackages = res.failedDeliveries
        ? packages.filter(
            (el) =>
              !res.failedDeliveries.some(
                (trackingNumber) => el.trackingNumber === trackingNumber
              )
          )
        : [...packages];
      createXLSX(filteredPackages);
      showFailedPackages(res.failedDeliveries);
      const payload = formatReceivedOrders(packages);
      if (payload?.pickups?.length) {
        await setConfirmedPickupsDeliveries(formatReceivedOrders(packages));
      }
    }
    setLoadingPackagesSave(false);
  };

  const handleClearAll = () => {
    const trackingNumbers = packages.map(
      ({ trackingNumber }) => trackingNumber
    );
    addWrongScanLog(trackingNumbers);
    clearPackages();
  };

  const modalSaveOrder = () => {
    if (packages.length === 0) {
      return;
    }

    if (
      packages.some(
        (delivery) =>
          delivery.multiPackages !== delivery.scannedPackages?.length
      )
    ) {
      return openModal(MissingMultiPackagesModal, {
        deliveries: packages
      });
    }

    const content = intl.formatMessage({
      id: `hubs.receive_new_pickup.modal.${
        useWeights ? 'weight' : 'size'
      }_confirm`
    });

    Modal.confirm({
      content: <div>{content}</div>,
      okButtonProps: { className: 'primaryButton' },
      cancelButtonProps: { className: 'secondaryButton' },
      onOk: async () => {
        handleSaveCurrentPackages();
      }
    });
  };
  const handleOnRemoveReceived = (businessName, delivery) => {
    const trackingNumber = delivery.trackingNumber;
    addDeliveryToPickups(businessName, delivery);
    removePackage(delivery._id);
    addWrongScanLog([trackingNumber]);
  };

  const handleStarChange = (value) => {
    setSelectedStar(value);
    clearPackages();
    if (!value) {
      clearPickups();
    }
    setIsMissedFirstMilePickups(false);
  };

  const handleSendingMissedPickups = async () => {
    try {
      let formattedPayload = {
        pickups: []
      };
      Object.values(pickups).forEach((missedPickup) => {
        if (missedPickup.deliveries?.length) {
          let deliveriesArray = [];
          missedPickup.deliveries.forEach((delivery) => {
            deliveriesArray.push(delivery?.trackingNumber);
          });

          formattedPayload.pickups.push({
            pickupRequestId: missedPickup.pickupRequestId,
            trackingNumbers: deliveriesArray
          });
        }
        if (missedPickup.deliveriesCount) {
          formattedPayload.pickups.push({
            pickupRequestId: missedPickup.pickupRequestId,
            missedOrdersCount: missedPickup.deliveriesCount
          });
        }
      });
      await setMissedPickupsDeliveries(formattedPayload);
      setIsMissedFirstMilePickups(false);
      clearPickups();
    } catch (error) {
      notify(error.message);
    }
  };

  const handleOnCheckBoxChange = (e) => {
    setIsGroupByBusiness(e.target.checked);
  };

  return (
    <div className="br-receive-new-pickups__container">
      <div>
        <div className="br-receive-new-pickups__star-container">
          <div className="br-receive-new-pickups__star-select">
            <span className="body-medium"> Select Star</span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children
                  ?.join('')
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={(trigger) => trigger.parentElement}
              onChange={handleStarChange}
              allowClear
            >
              {stars.map(({ _id, profile: { firstName, lastName } }) => (
                <Select.Option key={_id} value={_id}>
                  {firstName} {lastName}
                </Select.Option>
              ))}
            </Select>
          </div>
          <Checkbox
            onChange={handleOnCheckBoxChange}
            value={isGroupByBusiness}
            defaultChecked
          >
            {intl.formatMessage({
              id: 'hubs.receive_new_pickup.group_by_business'
            })}
          </Checkbox>
        </div>
        {!!selectedStar && !!firstMilePickupsCount && (
          <div className="br-receive-new-pickups__star-pickups-count-container">
            <span>
              {intl.formatMessage(
                {
                  id: 'hubs.receive_new_pickup.star_no_of_pickups'
                },
                {
                  count: (
                    <span className="br-receive-new-hubs__first-mile-count-info">
                      {firstMilePickupsCount}
                    </span>
                  )
                }
              )}
            </span>
          </div>
        )}

        <PickupsTabActions
          checkTrackingNumberExistance={handleTrackingNumberChange}
          checkSealNumberExistance={handleSealNumberChange}
          packageSizes={allPricingPackageSize}
          useWeights={useWeights}
          showConfirmButton={true}
          confirmButtonText={intl.formatMessage({
            id: 'common.confirm_receiving'
          })}
          handleOnConfirmReceiveClick={modalSaveOrder}
          buttonDisabled={loading}
        />
        <FormGroup className="mt-3">
          <div>
            <h4>{packages.length} Packages Scanned.</h4>
          </div>
        </FormGroup>
      </div>
      <div className={styles.hubButtons}>
        <Button
          className="primaryButton"
          disabled={loading}
          onClick={handleClearAll}
        >
          Clear
        </Button>
      </div>

      <hr />
      <Form form={form} component={false}>
        <NewSwappingContainer
          title1={`Pickups (${firstMilePickupsCount})`}
          title2={`Received (${packages.length})`}
          fmPickupsCount={firstMilePickupsCount}
          list={pickups}
          scannedList={isGroupByBusiness ? formatPackages(packages) : packages}
          buttonText={intl.formatMessage({
            id: `hubs.packages_debrief.actions.confirm_receiving_pickups`
          })}
          showExtraDataInReceivedTable={true}
          useWeights={useWeights}
          handleOnRemoveReceived={handleOnRemoveReceived}
          isMissedFirstMilePickups={isMissedFirstMilePickups}
          groupByBusiness={isGroupByBusiness}
          handleSizeChange={updatePackageWeight}
        />
      </Form>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  playBeep: () => dispatch(beep()),
  playWarning: () => dispatch(warning())
});

const mapStateToProps = ({ hubs }) => {
  return {
    ...hubs
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ReceiveNewPickups));
