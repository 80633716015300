import { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

import {
  HUB_DELIVERIES_COLUMNS,
  HUB_DELIVERIES_FILTERS,
  ISSUES,
  PENDING_TRANSIT_DATE_FORMAT,
  SUM_PENDING_IN_TRANSIT,
  SUM_PENDING_IN_TRANSIT_PLUS,
  TOGGLE_BUTTON_INITAL_VALUE,
  TOGGLE_BUTTONS_LIST,
  TOTAL_PENDING_IN_TRANSIT,
  TOTAL_PENDING_IN_TRANSIT_PLUS
} from 'constants/pending-transit';
import { TABLE_PAGE_SIZE_OPTIONS } from 'constants/helper';
import {
  exportHubDeliveries,
  getHubDeliveries,
  inTransitDeliveriesCount
} from 'services/ops-control';
import { getPaginationCount } from 'utils/helpers';
import { downloadFromUrl } from 'utils/download';

import BRTable from 'components/BRTable/BRTable';
import BRToggleButton from 'components/BRToggleButton/BRToggleButton';
import PendingTransitFilter from 'components/OpsControl/PendingTransit/PendingTransitFilter/PendingTransitFilter';
import GeneralInfoCard from 'components/OpsControl/GeneralInfoCard/GeneralInfoCard';
import { notify } from 'components/Notify/Notify';
import BRTag from 'components/BRTag/BRTag';
import PreviousDateCard from 'components/OpsControl/PreviousDateCard/PreviousDateCard';

import BackArrow from 'assets/imgRevamp/arrow-left.svg';
import { ReactComponent as SortingIcon } from 'assets/bosta-icons/shipment.svg';
import { ReactComponent as HubsIcon } from 'assets/bosta-icons/hubs.svg';
import { ReactComponent as Issues } from 'assets/bosta-icons/issues.svg';

import './HubDeliveries.less';

const HubDeliveries = ({
  hubDetails,
  setHubDetails,
  intl,
  allHubs,
  initalDate,
  fetchMainTableData
}) => {
  const refreshTable = useRef();
  const hasMounted = useRef(false);

  const [hubData, setHubData] = useState();
  const [transfferedTo, setTransferredTo] = useState();
  const [transfferedFrom, setTransferredFrom] = useState();
  const [toggleButtonValue, setToggleButtonValue] = useState(
    TOGGLE_BUTTON_INITAL_VALUE
  );
  const [selectedDate, setSelectedDate] = useState(initalDate);
  const [loading, setLoading] = useState(false);

  const acceptMethods = (refreshMethod) => {
    refreshTable.current = refreshMethod;
  };

  const updateTable = () => {
    refreshTable.current();
  };
  const formatTableData = (data) => {
    const formattedData = data.map((el) => {
      return {
        tracking_number: (
          <Link
            to={`/deliveries/${el?.tracking_number}/details`}
            style={{ textDecoration: 'underline' }}
            target="_blank"
          >
            {el?.tracking_number}
          </Link>
        ),
        trasferred_from: el?.transferred_from_hub_name,
        transferred_to: el?.transferred_to_hub_name,
        seal_number: el?.seal_number,
        transfer_aging: (
          <BRTag className={'hub-delieveries__table-tag '}>
            {el?.transfer_aging}
          </BRTag>
        ),
        e2e_aging: (
          <BRTag className={'hub-delieveries__table-tag '}>
            {el?.order_aging}
          </BRTag>
        )
      };
    });
    return formattedData;
  };

  const getTableDataPayload = ({
    page,
    limit,
    transferredTo,
    transferredFrom,
    searchInputText,
    isExport,
    selectedDateValue,
    toggleButton
  }) => {
    return {
      ...(!isExport && { limit }),
      ...(!isExport && { skip: (page - 1) * limit }),
      ...(transferredTo && { destinationHubIds: transferredTo }),
      ...(transferredFrom && { sourceHubIds: transferredFrom }),
      ...(searchInputText && { trackingNumberOrSealNumber: searchInputText }),
      ...(toggleButton === TOGGLE_BUTTONS_LIST[1].value && {
        isForwardDelivery: true
      }),
      ...(toggleButton === TOGGLE_BUTTONS_LIST[2].value && {
        isForwardDelivery: false
      }),
      date: selectedDateValue
        ? selectedDateValue.format(PENDING_TRANSIT_DATE_FORMAT)
        : selectedDate.format(PENDING_TRANSIT_DATE_FORMAT)
    };
  };

  const fetchTableData = async ({
    page,
    limit,
    transferredTo,
    transferredFrom,
    searchInputText,
    filterValues
  }) => {
    setTransferredTo(transferredTo);
    setTransferredFrom(transferredFrom);

    const selectedDateValue = filterValues?.selectedDate;
    const toggleButton = filterValues?.toggleButton;

    try {
      const payload = getTableDataPayload({
        page,
        limit,
        transferredFrom,
        transferredTo,
        searchInputText,
        isExport: false,
        selectedDateValue,
        toggleButton: toggleButton || toggleButtonValue
      });
      const res = await getHubDeliveries(hubDetails.id, payload);
      return {
        list: formatTableData(res?.data),
        total: getPaginationCount({ result: res?.data, page })
      };
    } catch (error) {
      notify(error.message);
    }
  };

  const exportCardsData = async ({
    isTransferredFrom,
    isTransitPlus,
    isInvestigation,
    isInvestigationPlus
  }) => {
    const payload = {
      date: selectedDate.format('YYYY-MM-DD'),
      ...(isTransferredFrom && { sourceHubIds: [hubDetails?.id] }),
      ...(!isTransferredFrom &&
        !isInvestigation &&
        !isInvestigationPlus && { destinationHubIds: [hubDetails?.id] }),
      ...(isTransitPlus && { minTransferAge: 1, maxTransferAge: 1 }),
      ...(isInvestigation && { minTransferAge: 2, maxTransferAge: 4 }),
      ...(isInvestigationPlus && { minTransferAge: 5 }),
      ...(!isTransitPlus &&
        !isInvestigation &&
        !isInvestigationPlus && { minTransferAge: 0, maxTransferAge: 0 }),
      ...(toggleButtonValue === TOGGLE_BUTTONS_LIST[1].title && {
        isForwardDelivery: true
      }),
      ...(toggleButtonValue === TOGGLE_BUTTONS_LIST[2].title && {
        isForwardDelivery: false
      })
    };
    try {
      const res = await exportHubDeliveries(hubDetails.id, payload);
      notify(res.message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  const fetchHubData = async () => {
    try {
      setLoading(true);
      const payload = {
        date: selectedDate.format(PENDING_TRANSIT_DATE_FORMAT),
        hubId: hubDetails.id
      };
      const res = await inTransitDeliveriesCount(payload);
      if (res?.data.length) {
        setHubData(res.data[0]);
      }
    } catch (error) {
      notify(error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchHubData();
  }, [selectedDate, toggleButtonValue]);

  const changeToggleButton = (value) => {
    setToggleButtonValue(value);
    refreshTable.current({
      filterValues: {
        toggleButton: value,
        selectedDate: selectedDate
      }
    });
  };

  const changeDateValue = (value) => {
    setSelectedDate(value);
    refreshTable.current({
      filterValues: {
        toggleButton: toggleButtonValue,
        selectedDate: value
      }
    });
  };
  const HubsFiltersDropdown = () => {
    return allHubs.map((hub) => {
      return { label: hub.name, value: String(hub._id) };
    });
  };

  const handleUrlDownload = () => {
    downloadFromUrl(hubData?.url, 'orders');
  };

  const exportTableData = async (
    {},
    { transferredFrom, transferredTo, searchInputText }
  ) => {
    const payload = getTableDataPayload({
      transferredFrom,
      transferredTo,
      searchInputText,
      isExport: true
    });
    try {
      const res = await exportHubDeliveries(hubDetails.id, payload);
      notify(res.message, 'success');
    } catch (error) {
      notify(error.message);
    }
  };

  const handleGoBackClick = () => {
    fetchMainTableData();
    setHubDetails();
  };

  return (
    <div className="hub-deliveries__container">
      <div className="hub-deliveries__header">
        <img
          role="button"
          onClick={handleGoBackClick}
          alt="Go Back"
          src={BackArrow}
          className="br-header__back-button"
        />
        <p className="display-xs hub-deliveries__title">{hubDetails?.name}</p>
      </div>
      <div className="pending-transit__header">
        <BRToggleButton
          buttonsList={TOGGLE_BUTTONS_LIST}
          setValue={setToggleButtonValue}
          value={toggleButtonValue}
          changeToggleButton={changeToggleButton}
        />
        <PendingTransitFilter
          intl={intl}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          updateTable={updateTable}
          changeDateValue={changeDateValue}
        />
      </div>
      <div className="hub-deliveries__general-cards_container">
        <GeneralInfoCard
          selectedDate={selectedDate}
          initalDate={initalDate}
          loading={loading}
          total={intl.formatMessage(
            {
              id: 'ops_control.hub_deliveries.overall_pending'
            },
            { total: SUM_PENDING_IN_TRANSIT(hubData, toggleButtonValue) }
          )}
          cardsData={TOTAL_PENDING_IN_TRANSIT(
            hubData,
            toggleButtonValue,
            exportCardsData
          )}
          Icon={SortingIcon}
          title={intl.formatMessage({
            id: 'ops_control.hub_deliveries.total_pending'
          })}
        />
        <GeneralInfoCard
          selectedDate={selectedDate}
          initalDate={initalDate}
          loading={loading}
          cardsData={TOTAL_PENDING_IN_TRANSIT_PLUS(
            hubData,
            toggleButtonValue,
            exportCardsData
          )}
          Icon={SortingIcon}
          title={intl.formatMessage({
            id: 'ops_control.hub_deliveries.total_pending_plus'
          })}
          total={intl.formatMessage(
            {
              id: 'ops_control.hub_deliveries.overall_pending_plus'
            },
            { total: SUM_PENDING_IN_TRANSIT_PLUS(hubData, toggleButtonValue) }
          )}
        />

        <GeneralInfoCard
          initalDate={initalDate}
          selectedDate={selectedDate}
          loading={loading}
          cardsData={ISSUES(hubData, toggleButtonValue, exportCardsData)}
          Icon={Issues}
          title={intl.formatMessage({
            id: 'ops_control.hub_deliveries.issues'
          })}
        />
      </div>
      <div className="hub-deliveries__table-container">
        {hubData?.url ? (
          <PreviousDateCard
            downloadFunction={handleUrlDownload}
            exportButtonTitle={intl.formatMessage({ id: 'common.export' })}
            Icon={HubsIcon}
            header={intl.formatMessage({
              id: 'ops_control.orders_table.header'
            })}
            exportHeader={intl.formatMessage({
              id: 'ops_control.orders_table.export_orders'
            })}
            discreption={intl.formatMessage({
              id: 'ops_control.orders_table.export_orders_discreption'
            })}
          />
        ) : (
          <BRTable
            searchPlaceholder={intl.formatMessage({
              id: 'ops_control.hub_deliveries.deliveries_table.search_placeholder'
            })}
            tableFilters={HUB_DELIVERIES_FILTERS(
              HubsFiltersDropdown(),
              transfferedTo,
              transfferedFrom
            )}
            showSearch
            showFilter
            exportListFileFunction={exportTableData}
            shareMethods={acceptMethods}
            listFunction={fetchTableData}
            pageSizeOptions={TABLE_PAGE_SIZE_OPTIONS}
            columns={HUB_DELIVERIES_COLUMNS}
            title={
              <div className="pending-transit__hubs-table-title">
                <HubsIcon />
                {intl.formatMessage({
                  id: 'ops_control.pending_transit.hubs_table.title'
                })}
              </div>
            }
          />
        )}
      </div>
    </div>
  );
};

export default HubDeliveries;
