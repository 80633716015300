import { useIntl } from 'react-intl';

import BRButton from 'components/BRButton/BRButton';

const FilterHeader = ({
  handleSubmitFilters,
  handleResetFilters,
  isExpanded
}) => {
  const intl = useIntl();
  return (
    <div className="br-filters-search__header">
      <h3 className="br-filters-search__header-title font-18 display-xs">
        {intl.formatMessage({
          id: 'common.filter'
        })}
      </h3>
      <div className="br-filters-search__header-actions">
        <BRButton
          label={intl.formatMessage({ id: 'common.clear' })}
          onClick={handleResetFilters}
          disabled={!isExpanded}
        />
        <BRButton
          type="primary"
          label={intl.formatMessage({ id: 'common.filter' })}
          onClick={handleSubmitFilters}
          disabled={!isExpanded}
        />
      </div>
    </div>
  );
};

export default FilterHeader;
