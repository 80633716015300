import classnames from 'classnames';
import { Typography } from 'antd';

import { isDefaultBostaCourier } from 'utils/helpers';
import aclMatrix, { VENDOR_MANAGER } from 'common/aclMatrix';

import PickupState from 'components/Pickups/components/PickupState/PickupState';
import PickupValidation from 'components/Pickups/components/PickupValidation/PickupValidation';

import { ReactComponent as PickupIcon } from 'assets/bosta-icons/Pickups.svg';

import './PickupSummary.less';

export default function PickupSummary({ pickup }) {
  const { puid, business, exception, numberOfParcels, pickupsCounter } = pickup;

  const businessName = isDefaultBostaCourier([
    ...aclMatrix.THREE_PL,
    VENDOR_MANAGER
  ])
    ? business?.name
    : business?._id;

  return (
    <div className="br-pickup-summary">
      <PickupIcon className="br-pickup-summary__icon" />

      <div className="br-pickup-summary-info">
        <Typography.Title level={4} className="br-pickup-summary-info__title">
          Pickup ID: {puid}
        </Typography.Title>

        <PickupState item={pickup} />

        <PickupValidation
          exception={exception}
          showValidColor={true}
          useEmptyPlaceholder={false}
        />

        <Typography.Text className="br-pickup-summary-info__business-name">
          {businessName}
        </Typography.Text>
      </div>

      <InfoCard title="Business Requested" value={numberOfParcels} />
      <InfoCard title="Star Received" value={pickupsCounter} />
      <InfoCard title="Hub Received" value={"TBA"} />
    </div>
  );
}

function InfoCard({ title, value }) {
  return (
    <div
      className={classnames('br-pickup-summary-info-card', {
        muted: !value
      })}
    >
      <Typography.Text className="br-pickup-summary-info-card__value">
        {value || '-'}
      </Typography.Text>
      <Typography.Text className="br-pickup-summary-info-card__title">
        {title}
      </Typography.Text>
    </div>
  );
}
