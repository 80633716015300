import { Card, Form, List } from 'antd';

import ScannedPackagesHeader from './components/ScannedPackagesHeader/ScannedPackagesHeader';
import EmptyDataLabel from './components/EmptyDataLabel/EmptyDataLabel';
import { useHubsContext } from 'containers/Hub/ReceiveFromBusiness/ReceiveFromBusiness';

import './ScannedPackages.less';

const ScannedPackages = () => {
  const { packages } = useHubsContext();
  return (
    <Card
      title={<ScannedPackagesHeader scannedPackagesCount={packages?.length} />}
      className="br-hubs-receive-from-business__pickup-scanning__scanned-packages"
    >
      <Form.Item name="deliveries" initialValue={packages}>
        <List
          dataSource={packages}
          renderItem={(item) => <List.Item>{item.trackingNumber}</List.Item>}
          locale={{
            emptyText: <EmptyDataLabel />
          }}
        />
      </Form.Item>
    </Card>
  );
};

export default ScannedPackages;
